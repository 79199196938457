<template>
  <div>
    <DimsTooltip :dark="index === 0 && conditionalOffers.includes(offer)" @click.stop="openDialog()">
      <p>Klik for at se dokumentation af udregningen.</p>
    </DimsTooltip>
    <v-dialog v-model="bpqDocumentationDialog">
      <v-container fluid>
        <v-card class="pa-6">
          <v-container>
            <h1>Udregning af kvalitetspoint</h1>
            <br />
            <v-card-text class="pl-0">
              <h3 class="mt-0">Procenter</h3>
              <div class="d-flex mt-0">
                <div class="pt-1" v-if="bpqRatioPercent">
                  <div class="d-flex">
                    <strong>Kvalitet:</strong>
                    <div class="ml-1">
                      {{ `${bpqRatioPercent}%` }}
                    </div>
                  </div>
                  <ul>
                    <li v-for="criteria in criterias" :key="criteria.key">
                      <div class="d-flex">
                        <strong>{{ criteria.label }}:</strong>
                        <div class="ml-1">
                          {{ criteria.weight }}%
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="ml-12 pt-1" v-if="pricePercent">
                  <div class="d-flex">
                    <strong>Pris:</strong>
                    <div class="ml-1">
                      {{ `${pricePercent}%` }}
                    </div>
                  </div>
                </div>
              </div>
              <h3 class="mt-6">Point</h3>
              <v-row class="mt-0">
                <v-col>
                  <strong>Point<sub>min.</sub>:</strong>
                  <span> 1</span>
                </v-col>
                <v-col>
                  <strong>Point<sub>max</sub>:</strong>
                  <span> 10</span>
                </v-col>
                <v-col>
                  <strong>Pris<sub>lavest</sub>:</strong>
                  <span> {{ $format.currency2(lowestPrice) }}</span>
                </v-col>
                <v-col>
                  <strong>Pris<sub>TCO</sub>:</strong>
                  <span>
                    {{
                      $format.currency2(calculateTCOPrice(offer, tender))
                    }}</span>
                </v-col>
              </v-row>

              <div class="pt-1">
                <div class="d-flex">
                  <br />
                  <strong>&alpha;:</strong>
                  <div class="ml-1">
                    <i>
                      (point<sub>min.</sub> - point<sub>max</sub>) / (pris<sub>lavest</sub>
                      x (point<sub>min.</sub> + pris procent) - pris<sub>lavest</sub>)
                    </i>
                    = {{ alpha }}
                  </div>
                </div>
              </div>
              <div class="pt-1">
                <div class="d-flex">
                  <br />
                  <strong>&beta;:</strong>
                  <div class="ml-1">
                    <i>point<sub>max</sub> - &alpha; x pris<sub>lavest</sub></i>
                    &cong; {{ (Math.round(beta * 100) / 100).toFixed(2) }}
                  </div>
                </div>
              </div>
              <div class="pt-1">
                <div class="d-flex">
                  <br />
                  <strong>Pris point:</strong>
                  <div class="ml-1">
                    <i><span>&alpha;</span> x pris<sub>TCO</sub> +
                      <span>&beta;</span></i>
                    &cong;
                    {{
                      (
                        Math.round(
                          calculatePriceScore(
                            conditionalOffers,
                            offer,
                            tender,
                          ) * 100,
                        ) / 100
                      ).toFixed(2)
                    }}
                  </div>
                </div>
              </div>
              <div class="d-flex mt-0">
                <div>
                  <h4 class="mt-2">Score</h4>
                  <div class="pt-1" v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <strong>{{ criteria.label }}:</strong>
                      <div class="ml-1">
                        {{ criteria.score }} point
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="pt-1">
                    <div class="d-flex">
                      <br />
                      <strong>Kvalitet:</strong>
                      <div class="ml-1">
                        {{ `${totalWeightedScoreString} point` }}
                      </div>
                    </div>
                  </div>
                  <div class="pt-1">
                    <div class="d-flex">
                      <br />
                      <strong>Pris:</strong>
                      <div class="ml-1">
                        {{
                          `${(
                            Math.round(
                              calculatePriceScore(
                                conditionalOffers,
                                offer,
                                tender,
                              ) * 100,
                            ) / 100
                          ).toFixed(2)} point`
                        }}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="pt-1">
                    <div class="d-flex">
                      <br />
                      <strong>Samlet antal point:</strong>
                      <div class="ml-1">
                        {{
                          `${Number(
                            (
                              Math.round(
                                (bpqRatioPercent / 100)
                                  * calculateBPQRatioScore(
                                    conditionalOffers,
                                    tender,
                                    offerEvaluation,
                                  )
                                  * 100,
                              ) / 100
                            ).toFixed(2),
                          )} + ${Number(
                            (
                              Math.round(
                                (pricePercent / 100)
                                  * calculatePriceScore(
                                    conditionalOffers,
                                    offer,
                                    tender,
                                  )
                                  * 100,
                              ) / 100
                            ).toFixed(2),
                          )} point`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-12">
                  <h4 class="mt-2">Vægtet</h4>
                  <div class="pt-1" v-for="criteria in criterias" :key="criteria.key">
                    <div class="d-flex">
                      <div class="ml-1">
                        {{
                          `${criteria.weight}%
                          x ${criteria.score}
                            = ${weightedScore(criteria)} point`
                        }}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="pt-1">
                    <div class="d-flex">
                      <br />
                      <div class="ml-1">
                        {{
                          `${`${bpqRatioPercent}% x ${totalWeightedBpqScore.toFixed(2)} = `}${Number(
                            (
                              Math.round(
                                (bpqRatioPercent / 100)
                                  * calculateBPQRatioScore(
                                    conditionalOffers,
                                    tender,
                                    offerEvaluation,
                                  )
                                  * 100,
                              ) / 100
                            ).toFixed(2),
                          )} point`
                        }}
                      </div>
                    </div>
                  </div>
                  <div class="pt-1">
                    <div class="d-flex">
                      <br />
                      <div class="ml-1">
                        {{
                          `${pricePercent}%
                           x ${(
                             Math.round(
                               calculatePriceScore(
                                 conditionalOffers,
                                 offer,
                                 tender,
                               ) * 100,
                             ) / 100
                          ).toFixed(2)}
                            = ${
                            (
                              (pricePercent / 100)
                              * Number(
                                (
                                  Math.round(
                                    calculatePriceScore(
                                      conditionalOffers,
                                      offer,
                                      tender,
                                    ) * 100,
                                  ) / 100
                                ),
                              )
                            ).toFixed(2)
                          } point`
                        }}
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="pt-1">
                    <div class="d-flex">
                      <br />
                      <div class="ml-1 double-underline">
                        {{
                          `${calculateTotalScore(
                            conditionalOffers,
                            offer,
                            tender,
                            offerEvaluation,
                          )} point`
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </v-card-text>
            <div class="d-flex justify-center">
              <v-card-actions>
                <v-btn class="secondary-button" @click="closeDialog()">
                  Luk
                </v-btn>
              </v-card-actions>
            </div>
          </v-container>
        </v-card>
      </v-container>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { sum, OfferEvaluation, WeightedScoreViewModel } from '@dims/components';
import {
  qualityRatioPercentage,
  calculateBPQRatioScore,
  calculatePriceScore,
  calculateTCOPrice,
  calculateTotalScore,
  getExtendedPriceSpan,
} from '@/services/utils5086';
import { Tender5086 } from '@/models/Tender';
import { Offer5086 } from '@/models/Offer';
import bpqCriteria from '@/services/bpqCriteria';

const { offer, tender, conditionalOffers, offerEvaluation } = defineProps<{
  offer: Offer5086,
  tender: Tender5086,
  conditionalOffers: Offer5086[],
  offerEvaluation?: OfferEvaluation,
  index: number }>();
const bpqDocumentationDialog = ref(false);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const criterias = ref<WeightedScoreViewModel[]>(null!);

function openDialog() {
  bpqDocumentationDialog.value = true;

  if (offer.isEvaluated && offerEvaluation) {
    criterias.value = bpqCriteria.getCategories(tender.data, offerEvaluation)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  } else {
    criterias.value = bpqCriteria.getEmptyCategories(tender.data)
      .map((c) => new WeightedScoreViewModel(c.key, c.label, c.score, c.weight));
  }
}

function closeDialog() {
  bpqDocumentationDialog.value = false;
}

const bpqRatioPercent = computed(() => qualityRatioPercentage(tender));

const pricePercent = computed(() => 100 - bpqRatioPercent.value);

const lowestPrice = computed(() => {
  const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender));
  return Math.min(...prices);
});

const alpha = computed(() => {
  const prices = conditionalOffers.map((o) => calculateTCOPrice(o, tender));
  const extendedPriceSpan = getExtendedPriceSpan(prices, lowestPrice.value, tender);
  return (
    (1 - 10)
      / (lowestPrice.value
        * (1 + (extendedPriceSpan ?? ((tender.data.bpqCostsPercentage ?? 0) / 100)))
        - lowestPrice.value)
  );
});

const beta = computed(() => 10 - alpha.value * lowestPrice.value);

function round2decimals(value: number) {
  return Math.round(value * 100) / 100;
}
function weightedScore(critera: WeightedScoreViewModel) {
  return round2decimals(critera.score * (critera.weight / 100));
}

const totalWeightedScoreString = computed(() => {
  const scores = criterias.value.map((c) => weightedScore(c).toString());
  return scores.join(' + ');
});

const totalWeightedBpqScore = computed(() => sum(criterias.value.map((c) => weightedScore(c))));

</script>

<style scoped>
.double-underline {
  text-decoration-line: underline;
  text-decoration-style: double;
}

ul {
  list-style-type: square;
}
</style>
@/services/BPQCriteria
